import { Header } from "./components/Header/Header";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useIsAuthenticated, useMsal, useMsalAuthentication } from "@azure/msal-react";
import { ProfileContent } from "./components/Profile/ProfileContent";
import { handleLogin } from "./components/Login/SignInMenuOption";
import { Outlet, useNavigate } from 'react-router-dom';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { doc, setDoc, getDoc } from 'firebase/firestore';

import { USER, db } from './Firebase';
import { createContext, useCallback, useEffect, useState } from "react";
import { AuthenticationResult, InteractionRequiredAuthError, InteractionStatus, InteractionType } from "@azure/msal-browser";
import { User } from "./types/User.type";
import { FirebaseError } from "firebase/app";
import { TosModal } from "./components/GenericModal/GenericModal";
import { loginRequest } from "./authConfig";

export const ParkLordContext = createContext<User | null>(null);

function App() {
    const navigate = useNavigate()
    const { instance, inProgress, accounts } = useMsal();
    const [userNameState, setUserNameState] = useState<string | null>(null);
    const [isUserParkLord, setIsUserParkLord] = useState<boolean | null>(null);
    const [parkLord, setParkLord] = useState<User | null>(null)
    const isAuthenticated = useIsAuthenticated();
    const [hasConsented, setHasConsented] = useState<boolean | null>(null);
    const [showModal, setShowModal] = useState(true);

    // Try to acquire token silently to see if user is authenticated
    const { login, result, error } = useMsalAuthentication(InteractionType.Silent, loginRequest);

    useEffect(() => {
        if (error instanceof InteractionRequiredAuthError) {
            // Trigger login if a user is not yet authenticated
            login(InteractionType.Redirect, loginRequest);
        }
    }, [error]);

    useMsalAuthentication(InteractionType.Redirect, loginRequest);

    const handleCloseModal = async () => {
        setShowModal(false);
        if (userNameState) {
            const docRef = doc(db, USER, userNameState.toLowerCase())
            await setDoc(docRef, { consentBool: true }, { merge: true })
                .then(async () => {
                    console.log("Successful");
                    setHasConsented(true)
                })
                .catch((error) => {
                    console.log(`Unsuccessful returned error ${error}`)
                });
            }
    }

    const manageUserState = useCallback(async (username: string) => {
        const manageUserStateWithRetry = async (username: string) => {
            try {
                // User state management.  Parklord is still only set if a Parklord
                // Check if the logged in user is a parklord
                const docRef = doc(db, USER, username.toLowerCase())
                const docSnap = await getDoc(docRef);
                if (docSnap.exists()) {
                    setHasConsented(docSnap.get("consentBool") == true)
                    setUserNameState(username)

                    if (docSnap.get("parkNumber")) { //is parklord
                        setParkLord(docSnap.data() as User)
                        setIsUserParkLord(true)
                    } else {
                        setIsUserParkLord(false)
                    }
                } else {
                    const newUser: User = {
                        parkNumber: null,
                        consentBool: false
                    }
                    console.log(`Create entry: ${newUser}`)
                    await setDoc(docRef, newUser, { merge: true })
                        .then(async () => {
                            console.log("Successful");
                            setIsUserParkLord(false)
                            setHasConsented(false)
                            setUserNameState(username)
                        })
                        .catch((error) => {
                            console.log(`Unsuccessful returned error ${error}`)
                        });
                }


            }
            catch (error) {
                // Firebase authentication can sometimes fail
                // so we retry until it succeeds
                if (error instanceof FirebaseError) {
                    console.log(`${error.code}: ${error.message}`)
                    await manageUserStateWithRetry(username)
                    return
                }
                console.log(error)
            }
        }
        await manageUserStateWithRetry(username)
    }, [])

    useEffect(() => {
        instance.handleRedirectPromise().then((result: AuthenticationResult | null) => {
            if (result && result.account)
                manageUserState(result.account.username)
        }).catch(error => {
            console.log(error)
        })
    }, [instance, manageUserState])

    useEffect(() => {
        if (isAuthenticated && accounts.length > 0)
            manageUserState(accounts[0].username)
    }, [isAuthenticated, accounts, manageUserState]);

    useEffect(() => {
        if (isUserParkLord)
            navigate("/myparks", { replace: true });
    }, [isUserParkLord])

    return (
        <div>
            <ParkLordContext.Provider value={parkLord}>
                <Header />
                <AuthenticatedTemplate>
                    {isUserParkLord == null || hasConsented == null ? (
                        <h4 className="display-6 my-2 text-center">Loading...</h4>
                    ) : (
                        <ProfileContent>
                            <Outlet />
                        </ProfileContent>

                    )
                    }{
                        hasConsented == false && (
                            <TosModal props={{ title: "Terms of Service Consent", show: showModal, handleClose: handleCloseModal }}></TosModal>
                        )
                    }
                </AuthenticatedTemplate>
            </ParkLordContext.Provider>
            <UnauthenticatedTemplate>
                {inProgress == InteractionStatus.Login ? (
                    <h4 className="display-6 my-2 text-center">Signing in...</h4>
                ) : inProgress == InteractionStatus.Logout ? (
                    <h4 className="display-6 my-2 text-center">Signing out...</h4>
                ) : (
                    <Container className="mt-5 text-center">
                        <Row>
                            <Col className="text-center">
                                <p className='display-6'>You are not signed in</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Button className='m-3' size="lg" onClick={() => handleLogin(instance)}>Sign in</Button>
                            </Col>
                        </Row>
                    </Container>
                )}
            </UnauthenticatedTemplate>
        </div>
    );
}

export default App;
