export const msalConfig = {
  auth: {
    clientId: "88763a77-b95c-490f-a8f0-43f596977220",
    authority: "https://login.microsoftonline.com/067e9632-ea4c-4ed9-9e6d-e294956e284b", // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
    redirectUri: process.env.REACT_APP_HOST || "http://localhost:3000",
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  }
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: ["User.Read"]
};

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
};


// RUN THIS
// az ad sp create-for-rbac --name carparking --role "Contributor" --scopes /subscriptions/<DEVSUBSCRIPTIONID>/resourceGroups/carparking
// ADD RESULTS OF THIS COMMAND INTO THE FOLLOWING FORMAT and store in GH secrets.AZURE_CREDENTIALS
// {
//   "clientId": "the app registration id",
//   "clientSecret": "secret",
//   "subscriptionId": "<DEVSUBSCRIPTIONID>",
//   "tenantId": "tenantId",
//   "activeDirectoryEndpointUrl": "https://login.microsoftonline.com",
//   "resourceManagerEndpointUrl": "https://management.azure.com/",
//   "activeDirectoryGraphResourceId": "https://graph.windows.net/",
//   "sqlManagementEndpointUrl": "https://management.core.windows.net:8443/",
//   "galleryEndpointUrl": "https://gallery.azure.com/",
//   "managementEndpointUrl": "https://management.core.windows.net/"
//   }

// Make sure that the AppRegistration => Authentication has the redirectURI's set
// https://parkseeq-dev.seequent.com
// https://parkseeq.seequent.com
// http://localhost:3000
